/* FORMAT CENTS TO EUROS */
export function formatCentsToEuros(cents: number, locale = "fr-FR"): string {
  const euros = cents / 100;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(euros);
}

/* FILE EXISTS */
export function fileExists(files: Array<unknown>, key: string) {
  return files.filter((file: unknown) => {
    const typedFile = file as { filename: string };
    const filename = typedFile.filename.startsWith(key);
    if (filename) {
      return filename;
    }
  });
}
