export type OperationResult = {
  idgame: string;
  title: string;
  type: string;
  available: boolean;
  contact_v2: boolean;
  date_debut: Date;
  date_fin: Date;
  date_fin_achat?: Date;
  date_fin_reclamation?: null;
  date_fin_regule?: Date;
  pays: [];
  langs: ["fr"];
  gabarit: string;
  _id: string;
  closed: boolean;
  animation: string;
  custom?: Custom;
  forms?: Forms;
  files?: Files;
};

export type Files = {
  [key: string]: {
    filename: string;
    uri: string;
  };
};

export type Forms = {
  [key: string]: {
    _id: string;
    type: string;
    lang: string;
  };
};

export type Custom = {
  titre_home?: string;
  text_home?: string;
  text_wait?: string;
  text_finish?: string;
  text_submited?: string; //consolante
  typebackground?: string;
  home_btn_txt?: string;
  disable_suivit?: boolean;
  banner_disable_cookie?: boolean;
  body_background_color?: string;
  disclaimer?: boolean;
  disclaimer_text?: string;
  disclaimer_country?: boolean;
  disclaimer_birthday?: boolean;
  disclaimer_yesno?: boolean;
  disclaimer_optin?: boolean;
  disclaimer_optintext?: string;
  border_radius_popup?: string;
  border_radius_button?: string;
  border_radius_fields?: string;
  font_family?: string;
  font_size?: string;
  primary_color_bg?: string;
  primary_color_txt?: string;
  secondary_color_bg?: string;
  secondary_color_txt?: string;
  color_input_border?: string;
  color_input_bg?: string;
  color_input_txt?: string;
  color_input_bg_focus?: string;
  color_input_bg_error?: string;
  color_error_txt?: string;
  color_bg_button_back?: string;
  color_text_button_back?: string;
  transparence_nav_links?: string;
  mobile_menu_position?: string;
  color_top_links?: string;
  color_top_bg_links?: string;
  burger_color?: string;
  links?: Array<string>;
  compteur?: string;
  compteur_limit_visible?: number;
  compteur_text_after?: string;
  lotperdu_libelle?: string;
  lotperdu_titre?: string;
  lotperdu_informations?: string;
  titre_animation?: string;
  text_animation?: string;
  wheel_quarters?: number;
  wheel_button?: string;
  wheel_sound?: boolean;
  memory_timer?: number;
  memory_text?: string;
  memory_textwin?: string;
  memory_textlose?: string;
  bandit_items_number?: number;
  bandit_text?: string;
  fontSize?: string;
  bodyText?: string;
  bodyBackground?: string;
  primaryBackground?: string;
  primaryText?: string;
  secondaryBackground?: string;
  secondaryText?: string;
  fontFamily?: string;
  cssRules?: string;
  custom_css?: string;
  facebook_link?: string;
  twitter_link?: string;
  discord_link?: string;
  instagram_link?: string;
  youtube_link?: string;
  snapchat_link?: string;
  linkedin_link?: string;
  pinterest_link?: string;
  disable_contact?: boolean;
  pagetitle?: string;
  banner_disable_animation?: boolean;
};

import { fetch } from "@/plugins/fetchPlugin";
import { FetchError } from "ofetch";
import { useRoute } from "vue-router/composables";

export const fetchOperation = async () => {
  try {
    const route = useRoute();

    if (route.params.operationId && route.params.operationId.length === 24) {
      /* OPERATION BY MONGO ID */
      return await fetch<OperationResult>(
        `/api/v2/formdata/${route.params.operationId}`,
        {
          method: "GET",
        }
      );
    } else {
      /* OPERATION BY HOSTNAME */

      /* HOSTNAME CLEAN */
      const hostname = window.location.hostname
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
        .split("/")[0];

      return await fetch<OperationResult>(`/api/v2/formdata/host/${hostname}`, {
        method: "GET",
      });
    }
  } catch (e) {
    if (e instanceof FetchError) {
      throw e.data;
    }
    throw e;
  }
};

export const fetchSubmission = async (submissionId: string, uid: string) => {
  try {
    /* GET SUBMISSION BY ID */
    return await fetch<OperationResult>(
      `/api/v2/submissions/${submissionId}?uid=${uid}`,
      {
        method: "GET",
      }
    );
  } catch (e) {
    if (e instanceof FetchError) {
      throw e.data;
    }
    throw e;
  }
};
